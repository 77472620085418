import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"

const Prosjekter = ({ data }) => {
  return (
    <Layout>
      <Seo title="Prosjekter" />

      <section className="prosjekter-main">
        <div className="container-fluid">
          <h1>Prosjekter</h1>
          {data?.wpPage?.acfProsjekter?.contentItems?.map((item, index) => (
            <div className="row custom_row" key={index}>
              <div className="col-lg-6">
                <div className="custom_row--inner">
                  {item.header && <h3>{item.header}</h3>}
                  <div
                    className={item.doubleColumns ? "double-columns" : ""}
                    dangerouslySetInnerHTML={{ __html: item.description }}
                  />
                </div>
              </div>
              <div className="col-lg-6 no-p">
                <img
                  className="img-fluid"
                  src={item?.image?.localFile?.publicURL}
                  alt=""
                />
              </div>
            </div>
          ))}

          <div className="prosjekter-main--gallery">
            <h3>{data?.wpPage?.acfProsjekter?.galleryHeader}</h3>
            <p>{data?.wpPage?.acfProsjekter?.galleryDescription}</p>
            <div className="row">
              {data?.wpPage?.acfProsjekter?.galleryItems?.map((item, index) => (
                <div className="col-md-3 col-6" key={index}>
                  <img
                    className="img-fluid"
                    src={item.localFile.publicURL}
                    alt=""
                  />
                </div>
              ))}
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export const query = graphql`
  query {
    wpPage(id: { eq: "cG9zdDo2OA==" }) {
      acfProsjekter {
        contentItems {
          header
          description
          doubleColumns
          image {
            localFile {
              publicURL
            }
          }
        }
        galleryHeader
        galleryDescription
        galleryItems {
          localFile {
            publicURL
          }
        }
      }
    }
  }
`

export default Prosjekter
